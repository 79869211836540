import React from 'react';

import { BackgroundImage, PrimaryButton, RichText } from 'components/ui';
import portfolio from 'data/portfolio.json';
import { buttons } from 'data/general.json';
import { portfolioToContactPage } from 'data/analytics.json';
import { useQueryContext } from 'hooks';

import { PortfolioMap } from '../PortfolioMap';
import {
    Container,
    Content,
    Title,
    Description,
    ProjectName,
    ProjectDescription,
    ProjectContainer,
    PortfolioItems,
    Link,
    PortfolioCta
} from './OurPortfolio.style';

export function OurPortfolio() {
    const {
        images: {
            portfolio: { hero }
        }
    } = useQueryContext();

    return (
        <Container>
            <BackgroundImage
                mobile={hero.mobile}
                tablet={hero.tablet}
                desktop={hero.desktop}
                alt={portfolio.hero.alt}
            />
            <Content>
                <Title>{portfolio.title}</Title>
                <Description>{portfolio.description}</Description>
                <PortfolioMap />
                <PortfolioItems>
                    {portfolio.portfolio.map((project, index) => (
                        <ProjectContainer key={`project-container-${index}`}>
                            <ProjectName>{project.name}</ProjectName>
                            <ProjectDescription>{project.description}</ProjectDescription>
                            <div>
                                {project.details.map((detail, index) => (
                                    <RichText key={`detail-${index}`}>
                                        <p>{`${detail.label} ${detail.value}`}</p>
                                    </RichText>
                                ))}
                            </div>
                            {project.link && (
                                <Link href={project.link} target="_blank">
                                    {project.linkLabel}
                                </Link>
                            )}
                        </ProjectContainer>
                    ))}
                </PortfolioItems>
                <PortfolioCta>
                    <h4>{portfolio.cta.text}</h4>
                    <PrimaryButton to="/contact/" name={portfolioToContactPage}>
                        {buttons.contactCTA}
                    </PrimaryButton>
                </PortfolioCta>
            </Content>
        </Container>
    );
}
