import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

interface ContainerProps {
    x: number;
    y: number;
}

const Container = styled.div<ContainerProps>`
    @keyframes ripple {
        100% {
            opacity: 0;
            transform: scale(4);
        }
    }

    :nth-of-type(3n + 1) {
        span {
            background: ${Colors.magenta};
        }
    }

    :nth-of-type(3n + 2) {
        span {
            background: ${Colors.green};
        }
    }

    :nth-of-type(3n + 3) {
        span {
            background: ${Colors.orange};
        }
    }

    span {
        position: absolute;
        border-radius: 50%;
        height: 1.6rem;
        width: 1.6rem;
        animation-name: ripple;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform: scale(0);
        top: ${({ y }) => `${y}px`};
        left: ${({ x }) => `${x}px`};

        :nth-child(1) {
            animation-delay: 0.8s;
        }

        :nth-child(2) {
            animation-delay: 2.4s;
        }

        @media ${MediaQueries.phone} {
            height: 0.8rem;
            width: 0.8rem;
        }
    }
`;

interface Props extends ContainerProps {
    index: number;
}

export function Marker({ x, y, index }: Props) {
    const [rendered, setRendered] = useState(false);

    useEffect(() => {
        const id = setTimeout(() => setRendered(true), [index * 500]);
        return () => clearTimeout(id);
    }, [index]);

    if (!rendered) return null;

    return (
        <Container x={x} y={y}>
            <span />
            <span />
            <span />
        </Container>
    );
}
