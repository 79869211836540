import React from 'react';

import { OurPortfolio } from 'components/portfolio';
import seo from 'data/seo.json';
import { SEO } from 'components/ui';

export default function Portfolio() {
    return (
        <>
            <SEO
                title={seo.portfolioTitle}
                description={seo.portfolioDescription}
                ogTitle={seo.portfolioOgTitle}
                ogDescription={seo.portfolioOgDescription}
                ogImage={seo.portfolioOgImage}
            />
            <OurPortfolio />
        </>
    );
}
