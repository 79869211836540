import React from 'react';

import { BackgroundImage } from 'components/ui';
import portfolio from 'data/portfolio.json';
import { MediaQueries } from 'environment';
import { useMediaQuery, useQueryContext } from 'hooks';

import { Marker } from './Marker';

const DESKTOP_MARKERS = [
    { x: 280, y: 160 },
    { x: 185, y: 205 },
    { x: 200, y: 110 },
    { x: 550, y: 80 },
    { x: 660, y: 100 },
    { x: 600, y: 50 },
    { x: 940, y: 185 },
    { x: 970, y: 300 }
];

const TABLET_MARKERS = [
    { x: 110, y: 75 },
    { x: 340, y: 35 },
    { x: 480, y: 70 },
    { x: 510, y: 150 }
];

const PHONE_MARKERS = [
    { x: 60, y: 50 },
    { x: 190, y: 20 },
    { x: 255, y: 45 },
    { x: 275, y: 80 }
];

export function PortfolioMap() {
    const isDesktop = useMediaQuery(MediaQueries.desktop);
    const isTablet = useMediaQuery(MediaQueries.tablet);
    const {
        images: {
            portfolio: { image }
        }
    } = useQueryContext();

    const markers = isDesktop ? DESKTOP_MARKERS : isTablet ? TABLET_MARKERS : PHONE_MARKERS;
    return (
        <BackgroundImage
            position="relative"
            mobile={image.mobile}
            tablet={image.tablet}
            desktop={image.desktop}
            alt={portfolio.alt}
        >
            {markers.map((position, index) => (
                <Marker key={`marker-${index}`} index={index} {...position} />
            ))}
        </BackgroundImage>
    );
}
