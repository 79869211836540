import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Colors, MediaQueries } from 'environment';
import {
    DESKTOP_GRID_SIZE,
    TABLET_GRID_SIZE,
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING
} from 'consts';

export const Container = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    color: ${Colors.violet};
`;

export const Content = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    color: ${Colors.violet};
    z-index: 2;
    align-items: center;
    width: 100%;
    padding: 12rem 2rem 5rem 2rem;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE + 4}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        padding: 12rem 0 3rem 0;
    }

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem 0 2rem;
    }
`;

export const Title = styled.h1`
    text-align: center;
    padding-bottom: 2rem;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 10 + DESKTOP_GRID_SPACING * 9}rem;
    }

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        line-height: 5rem;
    }
`;

export const Description = styled.h4`
    font-weight: normal;
    text-align: center;
    max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
    padding-bottom: 8rem;

    @media ${MediaQueries.phone} {
        padding-bottom: 4rem;
    }
`;

export const ProjectName = styled.h3`
    padding-bottom: 2rem;

    @media ${MediaQueries.phone} {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 3.8rem;
    }
`;

export const ProjectDescription = styled.p`
    padding-bottom: 2rem;
`;

export const ProjectContainer = styled.div`
    padding-bottom: 8rem;

    @media ${MediaQueries.phone} {
        padding-bottom: 6rem;
    }
`;

export const PortfolioItems = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 12rem;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
    }

    @media ${MediaQueries.phone} {
        padding-top: 6rem;
    }
`;

export const Link = styled(OutboundLink)`
    display: flex;
    padding-top: 2rem;
    border-bottom: 0.2rem transparent solid;
    color: ${Colors.magenta};
    cursor: pointer;
    transition: border-color 0.2s;
    width: fit-content;
    &:hover {
        border-color: ${Colors.magenta};
    }
`;

export const PortfolioCta = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        margin-top: 6rem;
        margin-bottom: 4rem;
    }
`;
